
import Vue from "vue"
import DefaultPageWrapper from "@/components/DefaultPageWrapper"
import WeatherExportAsBtn from "@/components/weather/WeatherExportAsBtn"
import {
  AnalyticsEvent,
  AnalyticsEventPageId,
  InfoPage,
  WeatherDataResponse,
} from "@evercam/shared/types"
import { useProjectStore } from "@evercam/dashboard/stores/project"
import { mapStores } from "pinia"
import { weatherIcons } from "@evercam/shared/icons/weatherIcons"
import { useAccountStore } from "@evercam/dashboard/stores/account"
import { useWeatherStore } from "@evercam/dashboard/stores/weather"
import { WeatherApi } from "@evercam/shared/api/weatherApi"
import { weatherReportHeaders } from "@evercam/dashboard/components/weather/weatherReportHeaders"

export default Vue.extend({
  name: "WeatherReport",
  meta: {
    pageId: AnalyticsEventPageId.WeatherReport,
  },
  components: {
    DefaultPageWrapper,
    WeatherExportAsBtn,
  },
  middleware: ({ redirect, $permissions }) => {
    if ($permissions.project.has.go()) {
      redirect(
        `${useProjectStore().projectRoute}/info/${InfoPage.WeatherReport}`
      )

      return
    }
  },
  data() {
    return {
      weatherData: [] as WeatherDataResponse[],
      isLoading: false,
      startDateMenu: false,
      endDateMenu: false,
      weatherIcons,
      showDialog: false,
      fromDate: this.$moment().subtract(20, "day").format("YYYY-MM-DD"),
      toDate: this.$moment().format("YYYY-MM-DD"),
    }
  },
  computed: {
    ...mapStores(useProjectStore, useAccountStore, useWeatherStore),
    isMobile(): boolean {
      return this.$device.isMobile
    },
    headers(): Record<string, unknown>[] {
      return weatherReportHeaders
    },
    maxDate(): string {
      return this.$moment().toISOString()
    },
    minDate(): string {
      // NOTE: min date is 23/12/2024 for now
      return this.$moment.utc("2024-12-23").toISOString()
    },
    startDate(): string {
      return this.$moment
        .tz(this.fromDate, this.projectStore.selectedProject.timezone)
        .format("MMM DD, YYYY")
    },
    endDate(): string {
      return this.$moment
        .tz(this.toDate, this.projectStore.selectedProject.timezone)
        .format("MMM DD, YYYY")
    },
    calendarStartDateTime(): string {
      return this.$moment
        .tz(this.fromDate, this.projectStore.selectedProject.timezone)
        .format("YYYY-MM-DD")
    },
    calendarEndDateTime(): string {
      return this.$moment
        .tz(this.toDate, this.projectStore.selectedProject.timezone)
        .format("YYYY-MM-DD")
    },
  },
  async mounted() {
    this.$analytics.saveEvent(AnalyticsEvent.PageView)
    await this.fetchWeatherData()
    this.weatherStore.isPersistent = false
  },
  beforeDestroy() {
    this.weatherStore.isPersistent = true
  },
  methods: {
    getWeatherIconSrc(index: number) {
      const iconName = weatherIcons[index]?.slug

      return iconName
        ? require(`@evercam/shared/assets/img/icons/weather/animated/${iconName}.svg`)
        : ""
    },
    sortByDateDesc(array: WeatherDataResponse[]) {
      return array.sort((a, b) => Date.parse(b.date) - Date.parse(a.date))
    },
    async getCurrentDayData(): Promise<WeatherDataResponse> {
      try {
        const todaysWeather = await WeatherApi.current.getCurrentWeather({
          projectExid: this.projectStore.selectedProject.exid,
        })

        return this.processCurrentDayData(todaysWeather)
      } catch (error) {
        console.error("Failed to fetch current day data!", error)

        return {} as WeatherDataResponse
      }
    },
    processCurrentDayData(todaysWeather) {
      const calculateAverage = (values) => {
        const sum = values.reduce((acc, curr) => acc + curr, 0)

        return Number((sum / values.length).toFixed(1))
      }
      const calculateMin = (data, key) => {
        return Number(Math.min(...data.map((item) => item[key])).toFixed(1))
      }
      const calculateMax = (data, key) => {
        return Number(Math.max(...data.map((item) => item[key])).toFixed(1))
      }

      return {
        date: this.$moment(todaysWeather[0].hour).format("YYYY-MM-DD"),
        avgtemp: calculateAverage(todaysWeather.map((item) => item.temp)),
        minTemp: calculateMin(todaysWeather, "temp"),
        maxTemp: calculateMax(todaysWeather, "temp"),
        minPrecip: calculateMin(todaysWeather, "precip"),
        maxPrecip: calculateMax(todaysWeather, "precip"),
        minPressure: calculateMin(todaysWeather, "pressure"),
        maxPressure: calculateMax(todaysWeather, "pressure"),
        minWindSpeed: calculateMin(todaysWeather, "windSpeed"),
        maxWindSpeed: calculateMax(todaysWeather, "windSpeed"),
        minFeelsLike: calculateMin(todaysWeather, "feelsLike"),
        maxFeelsLike: calculateMax(todaysWeather, "feelsLike"),
        minClouds: calculateMin(todaysWeather, "clouds"),
        maxClouds: calculateMax(todaysWeather, "clouds"),
        minHumidity: calculateMin(todaysWeather, "humidity"),
        maxHumidity: calculateMax(todaysWeather, "humidity"),
        weather: [
          todaysWeather[1]?.weather ?? todaysWeather[0]?.weather ?? {},
          todaysWeather[7]?.weather ?? {},
          todaysWeather[13]?.weather ?? {},
          todaysWeather[19]?.weather ?? {},
        ],
      } as WeatherDataResponse
    },
    async fetchWeatherData(params: Record<string, unknown> = {}) {
      this.isLoading = true
      this.weatherData = []
      let data: WeatherDataResponse[] = []
      try {
        const response = await WeatherApi.weather.getWeatherData({
          projectExid: this.projectStore.selectedProject.exid,
          payload: {
            scope: "daily",
            startDate: this.$moment(this.fromDate).format("YYYY-MM-DD"),
            endDate: this.$moment(this.toDate).format("YYYY-MM-DD"),
            ...params,
          },
        })
        data = response.data
        let currentDayData: WeatherDataResponse = null
        if (
          this.$moment(this.toDate).format("DD-MM-YYYY") ===
          this.$moment().format("DD-MM-YYYY")
        ) {
          currentDayData = await this.getCurrentDayData()
          this.weatherData = [currentDayData]
        }
        this.weatherData = this.sortByDateDesc([
          ...data,
          ...this.weatherData,
        ] as WeatherDataResponse[])
      } catch (error) {
        console.error(error)
      } finally {
        this.isLoading = false
      }
    },
    async onChangeStartDate(date) {
      this.$analytics.saveEvent(AnalyticsEvent.WeatherReportSelectStartDate, {
        date,
      })
      this.fromDate = date
      await this.fetchWeatherData()
    },
    async onChangeEndDate(date) {
      this.$analytics.saveEvent(AnalyticsEvent.WeatherReportSelectEndDate, {
        date,
      })
      this.toDate = date
      await this.fetchWeatherData()
    },
    viewWeatherChart(item: WeatherDataResponse) {
      this.$analytics.saveEvent(AnalyticsEvent.WeatherReportViewChart, {
        item,
      })
      this.weatherStore.targetTimestamp = this.$moment(item.date).format(
        "YYYY-MM-DD 12:00:00"
      )
      this.weatherStore.changeWeatherVisibility(true)
    },
  },
})
